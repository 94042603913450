.aup--container,
.aup--list__container,
.aup--presidium__container {
  display: flex;
  flex-direction: column;
}

.aup--container {
  margin-top: 4vh;
}
.aup--list__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}
.aup--presidium__container {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-top: 4vh;
}

.aup--list__title,
.aup--presidium__title {
  font-weight: 900;
  font-size: var(--font-size-title);
  text-transform: uppercase;
}

.aup--list {
  border: 1px solid var(--border-color);
  border-radius: 16px;
  font-size: 1.5rem;
  margin-top: 4rem;
  padding: 1rem;
}
.aup--list__line {
  font-weight: 700;
  margin-left: 1.75rem;
}

.aup--card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  border-radius: 16px;
  max-width: 20rem;
  padding: 1rem;
}
.aup--card__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4vh;
  margin-bottom: 2rem;
  padding: 2rem;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  border-radius: 16px;
  column-gap: 5rem;
  row-gap: 2rem;
}
.aup--card__img {
  border-radius: 8px;
  max-height: 20rem;
  object-fit: cover;
}
.aup--card__text-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  justify-content: space-around;
}
.aup--card__full-name {
  margin-top: 1rem;
  line-height: 2.5rem;
  font-weight: 900;
}
.aup--card__job-title {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .aup--list {
    font-size: 0.75rem;
    margin-top: 4vh;
  }
  .aup--list__line {
    margin-left: 1rem;
  }
  .aup--card__container {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
    column-gap: none;
    row-gap: none;
  }
  .aup--card__full-name {
    font-size: 1.2rem;
  }
  .aup--card__job-title {
    font-size: 0.95rem;
    padding-bottom: 0.5rem;
  }
  .aup--card__img {
    max-height: 15rem;
  }
}
