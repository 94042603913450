.footer--container {
  width: 100%;
  border-top: 1px solid var(--border-color);
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color);
  width: 80%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.footer--left__container {
  text-align: left;
}
.footer--left__title {
  text-align: left;
  font-size: 1rem;
}
.footer--left__text {
  font-size: 1rem;
  max-width: 40rem;
}

.footer--right__text {
  font-size: 1rem;
}

@media (max-width: 1325px) {
  .footer {
    flex-direction: column;
  }
  .footer--right__text {
    margin: 2vh;
  }
}
