.header--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 85%;
  max-width: 100rem;
}

.header--icon {
  height: 4.8rem;
  margin-left: 1rem;
  width: 4rem;
}

.header--nav__links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.header--link {
  color: var(--text-color);
  transition: color 0.2s, transform 0.2s;
  text-decoration: none;
  font-size: 0.7rem;
  font-weight: 900;
  margin-left: 1rem;
  margin-right: 2rem;
}

.header--link:hover {
  cursor: pointer;
  color: var(--hover-text-color);
  transform: translateY(-1px);
  text-decoration: underline;
}

.header--divider {
  display: flex;
  border-left: 1px solid var(--hover-text-color);
  border-right: 1px solid #16222c;
  height: 3rem;
  margin-right: 1rem;
  margin-left: 1rem;
  width: 1px;
}

.header--button {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s, transform 0.2s;
}
.header--button:hover {
  cursor: pointer;
  color: var(--hover-text-color);
  transform: translateY(-1px);
  text-decoration: underline;
}
.header--button__icon {
  width: 100%;
  height: 100%;
}

@media (max-width: 1325px) {
  .header {
    justify-content: space-between;
  }

  .header--link {
    display: none;
  }

  .header--button {
    display: flex;
  }

  .header--nav__links {
    display: none;
    position: absolute;
    top: 100px;
    right: 0;
    background-color: var(--primary-color);
    flex-direction: column;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid var(--border-color);
  }

  .header--nav__links.open {
    display: flex;
  }

  .header--link {
    display: block;
    margin: 10px 0;
    font-size: 1rem;
  }

  .header--divider {
    display: none;
  }
}

@media (max-width: 768px) {
  .header--icon {
    height: 2.5rem;
    margin-left: 1rem;
    width: 2rem;
  }
}
