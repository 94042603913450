:root {
  --font-family: "Open Sans", sans-serif;
  --primary-color: #f5f5f5;
  --text-color: #293241;
  --hover-text-color: #ec2127;
  --border-color: #c5c5c5;
  --font-size-title: 4rem;
  --font-size-title-text: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 86.5vh;
}

body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--primary-color);
  color: var(--text-color);
}

main {
  flex: 1;
}

@media (max-width: 768px) {
  :root {
    --font-size-title: 2rem;
    --font-size-title-text: 1rem;
  }
}
