.table,
.table--cell,
.table--cell__header {
  border: solid 1px var(--border-color);
}
.table {
  border-radius: 16px;
  width: 100%;
}
.table--cell__header {
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 2rem;
  border-radius: 14px;
}
.table--cell {
  padding: 2rem;
  border-radius: 14px;
}
.table--arrow {
  margin-left: 0.5rem;
  padding-top: 0.5rem;
}
