.wtp--container {
  display: flex;
  flex-direction: column;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.wtp--table {
  display: flex;
  flex-direction: row;
  margin-top: 4vh;
  margin-left: auto;
  margin-right: auto;
  gap: 2rem;
  width: 80%;
}

.wtp--title__container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.wtp--title {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 4rem;
}
.wtp--title__text {
  font-size: 2rem;
}
.wtp--title,
.wtp--title__text {
  text-align: left;
}
