.cnp--container {
  display: flex;
  flex-direction: column;
  margin-top: 4vh;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.cnp--title {
  font-weight: 900;
  font-size: 4rem;
  text-transform: uppercase;
}
.cnp--title__text {
  font-size: 2rem;
}

.cnp--contacts__container {
  margin-top: 4vh;
}

.cnp--email {
  font-size: 2rem;
}

.cnp--card__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%;
  margin-top: 2rem;
  padding: 2rem;
  border: solid 1px var(--border-color);
  border-radius: 16px;
  gap: 1.25rem;
}

.cnp--card {
  display: flex;
  flex-direction: row;
  transition: color 0.2s, transform 0.2s;
}
.cnp--card:hover {
  transform: translateY(-1px);
  cursor: pointer;
  .cnp--yt__icon {
    color: #ff0033;
  }
  .cnp--tg__icon {
    color: #2ea2d4;
  }
  .cnp--vk__icon {
    color: #097bff;
  }
}

.cnp--vk__icon,
.cnp--tg__icon,
.cnp--yt__icon {
  transition: color 0.2s;
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
}

.cnp--link__text {
  font-weight: normal;
  transition: color 0.2s;
}
.cnp--link__text:hover {
  color: var(--hover-text-color);
  text-decoration: underline;
}

a {
  all: unset;
}
