.cap--container {
  display: flex;
  flex-direction: column;
}

.cap--container {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

.cap--list__title {
  font-weight: 900;
  font-size: 4rem;
  text-transform: uppercase;
}
.cap--list__title-text {
  font-size: 2rem;
}
.cap--list {
  border: 1px solid var(--border-color);
  border-radius: 16px;
  font-size: 1.5rem;
  margin-top: 4rem;
  padding: 1rem;
}
.cap--list__line {
  font-weight: 700;
  margin-left: 1.75rem;
}
